@font-face {
  font-family: JetBrains Sans;
  src:
    url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Light.woff2)
      format("woff2"),
    url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Light.woff)
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: JetBrains Sans;
  src:
    url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Regular.woff2)
      format("woff2"),
    url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Regular.woff)
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: JetBrains Sans;
  src:
    url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-SemiBold.woff2)
      format("woff2"),
    url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-SemiBold.woff)
      format("woff");
  font-weight: 600;
  font-style: normal;
}

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: JetBrains Sans;
    src:
      url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans.woff2)
        format("woff2 supports variations"),
      url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans.woff2)
        format("woff2-variations"),
      url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans.woff)
        format("woff-variations");
    font-weight: 100 900;
    font-style: normal;
  }
}

@font-face {
  font-family: JetBrainsMono;
  src:
    url(https://jetbrains.com/lp/mono/static/JetBrainsMono-Light-c91599d112d3f77413bd94c6d7d7cbf4.woff2)
      format("woff2"),
    url(https://jetbrains.com/lp/mono/static/JetBrainsMono-Light-6b4c5a8e609e5a8b1a10d5ccd4ebff9a.woff)
      format("woff"),
    url(https://jetbrains.com/lp/mono/static/JetBrainsMono-Light-5f68b90fb3ae2b26792475f2e92f966a.ttf)
      format("truetype");
  font-weight: 300;
  font-style: normal;
}
