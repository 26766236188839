@font-face {
  font-family: JetBrains Sans;
  src: url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Light.woff2") format("woff2"), url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: JetBrains Sans;
  src: url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Regular.woff2") format("woff2"), url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: JetBrains Sans;
  src: url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-SemiBold.woff2") format("woff2"), url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: JetBrains Sans;
    src: url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans.woff2") format("woff2 supports variations"), url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans.woff2") format("woff2-variations"), url("https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans.woff") format("woff-variations");
    font-weight: 100 900;
    font-style: normal;
  }
}

@font-face {
  font-family: JetBrainsMono;
  src: url("https://jetbrains.com/lp/mono/static/JetBrainsMono-Light-c91599d112d3f77413bd94c6d7d7cbf4.woff2") format("woff2"), url("https://jetbrains.com/lp/mono/static/JetBrainsMono-Light-6b4c5a8e609e5a8b1a10d5ccd4ebff9a.woff") format("woff"), url("https://jetbrains.com/lp/mono/static/JetBrainsMono-Light-5f68b90fb3ae2b26792475f2e92f966a.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: JetBrains Mono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-top-full {
  top: -100%;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.m-auto {
  margin: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.aspect-square {
  aspect-ratio: 1;
}

.size-16 {
  width: 4rem;
  height: 4rem;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-24 {
  height: 6rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-viewport {
  height: 100dvh;
}

.min-h-28 {
  min-height: 7rem;
}

.min-h-full {
  min-height: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-24 {
  width: 6rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-4 {
  min-width: 1rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.list-inside {
  list-style-position: inside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-light\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: #7d7d7d40;
}

.self-center {
  align-self: center;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-blue {
  --tw-border-opacity: 1;
  border-color: rgb(8 124 250 / var(--tw-border-opacity));
}

.border-light\/25 {
  border-color: #7d7d7d40;
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(254 40 87 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(253 182 13 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/85 {
  background-color: #000000d9;
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(33 215 137 / var(--tw-bg-opacity));
}

.bg-green\/85 {
  background-color: #21d789d9;
}

.bg-main {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 28 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(254 40 87 / var(--tw-bg-opacity));
}

.bg-red\/85 {
  background-color: #fe2857d9;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(253 182 13 / var(--tw-bg-opacity));
}

.bg-yellow\/85 {
  background-color: #fdb60dd9;
}

.bg-none {
  background-image: none;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-mono {
  font-family: JetBrains Mono, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
}

.font-sans {
  font-family: JetBrains Sans, Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(8 124 250 / var(--tw-text-opacity));
}

.text-light {
  --tw-text-opacity: 1;
  color: rgb(125 125 125 / var(--tw-text-opacity));
}

.text-light\/35 {
  color: #7d7d7d59;
}

.text-light\/50 {
  color: #7d7d7d80;
}

.text-main {
  --tw-text-opacity: 1;
  color: rgb(25 25 28 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/50 {
  color: #ffffff80;
}

.text-white\/75 {
  color: #ffffffbf;
}

.no-underline {
  text-decoration-line: none;
}

.opacity-10 {
  opacity: .1;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.first-letter\:uppercase:first-letter {
  text-transform: uppercase;
}

.hover\:rotate-90:hover {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 124 250 / var(--tw-bg-opacity));
}

.hover\:bg-green\/90:hover {
  background-color: #21d789e6;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-green:hover {
  --tw-text-opacity: 1;
  color: rgb(33 215 137 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:top-0:focus {
  top: 0;
}

.focus\:bg-green:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(33 215 137 / var(--tw-bg-opacity));
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-green:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(33 215 137 / var(--tw-ring-opacity));
}

.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: .75;
}

.active\:bg-green\/90:active {
  background-color: #21d789e6;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-main:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(25 25 28 / var(--tw-border-opacity));
}

.disabled\:border-red:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(254 40 87 / var(--tw-border-opacity));
}

.disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.disabled\:text-pale:disabled {
  --tw-text-opacity: 1;
  color: rgb(205 205 205 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}

@media (width >= 768px) {
  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:min-h-36 {
    min-height: 9rem;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:gap-3 {
    gap: .75rem;
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:border-2 {
    border-width: 2px;
  }

  .md\:border-\[3px\] {
    border-width: 3px;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:font-bold {
    font-weight: 700;
  }

  .md\:font-normal {
    font-weight: 400;
  }

  .md\:tracking-tight {
    letter-spacing: -.025em;
  }
}

@media (orientation: portrait) {
  .portrait\:w-3\/4 {
    width: 75%;
  }
}

@media (orientation: landscape) {
  .landscape\:h-3\/4 {
    height: 75%;
  }
}
/*# sourceMappingURL=index.f60e7fda.css.map */
